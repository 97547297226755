import { render, staticRenderFns } from "./updatetable.vue?vue&type=template&id=ecc08ce6&scoped=true&"
import script from "./updatetable.vue?vue&type=script&lang=js&"
export * from "./updatetable.vue?vue&type=script&lang=js&"
import style0 from "./updatetable.vue?vue&type=style&index=0&id=ecc08ce6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecc08ce6",
  null
  
)

export default component.exports