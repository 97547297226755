<template>
  <div class="updateBox">
    <div class="headBox">更新表格</div>
    <el-card class="box-card" :style="GetWindowHeight">
      <div class="cus_upload">
        <el-upload
          class="upload-demo"
          action=""
          accept=".xls, .xlxs,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          :before-upload="beforeAvatarUpload"
          :on-change="imgPreview"
          auto-upload
          :http-request="cus_improt"
          :show-file-list='false'
        >
          <el-button class="cus_btn" icon="icon iconfont icon-shangchuanbiaoge">上传表格</el-button>
        </el-upload>
      </div>
    </el-card>
  </div>
</template>

<script>
import api from "@/api/guildManageapi";
export default {
  name: "updatetable",
  data() {
    return {
      fileList:[],
      excelFlie:null,
      flieUrl:'',
      GetWindowHeight: {
        height: ''
      }
    };
  },
  created() {
    window.addEventListener('resize', this.getHeight) // 注册监听器
    this.getHeight() // 页面创建时先调用一次
  },
  methods: {
    getHeight () {
      // 获取浏览器高度，减去顶部导航栏的值70（可动态获取）,再减去head-DIV高度值80
      this.GetWindowHeight.height = window.innerHeight - 120 + 'px'
      // console.log(this.GetWindowHeight.height)
    },
    beforeAvatarUpload(file){
       let fileName = file.name;
      let regex = /(.xls|.xlsx)$/;
      if (!(regex.test(fileName.toLowerCase()))) {
        this.$message.error("请选择Excel文件");
        return false
      } 
    },
    imgPreview(file){
      this.flieUrl = URL.createObjectURL(file.raw);
          this.excelFlie= file.raw;
    },
    async cus_improt(){
      let formData = new FormData()
      formData.append('file',this.excelFlie)
      let _that = this
      const data =await api.updatate(formData)
      if(data.status_code === 200){
        _that.$message({
          message: "上传成功",
          type: "success",
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "./css/headCss.less";
// .updateBox /deep/ .el-card.is-always-shadow {
//   height: 630px;
// }
.updateBox /deep/ .el-card__body {
  height: 100%;
}
.cus_upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.updateBox /deep/ .el-button {
  width: 154px;
  height: 48px;
  font-size: 20px;
  color: #333333;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}
.updateBox /deep/ .iconfont {
  margin-right: 3px;
}
</style>